import { WebsiteVehicleList } from "models/websiteVehicleList";
import { useContext, useEffect, useRef, useState } from "react";
import { numberWithCommas } from "utils/commonUtils";
import useImageUtil from "utils/ImageUtil";
import useIntersectionObserver from "components/Hooks/useIntersectionObserver";
import { AppContext } from "context/appContext";
import dynamic from "next/dynamic";

const HtmlComponent = dynamic(() => import("components/Html/Html"));
const SpecialCarCard = dynamic(() => import("components/vehicles/SRP/SpecialCarCard"));

const initState: WebsiteVehicleList[] = [];

const SpecialVehiclesInCard = ({ settings }: any) => {
    const {
        settings: { companyInfo },
    } = useContext(AppContext);
    const [data, setData] = useState(initState);

    const similarVehiclesCarouselRef = useRef(null);
    const similarVehiclesCarouselVisible = useIntersectionObserver(
        similarVehiclesCarouselRef
    );

    const {
        vehicleFourChose,
        vehiclesToShow,
        orderBy,
        showBy,
    } = settings;

    const customStocks = `${vehicleFourChose}`.replace(/,/g, "|");

    const getSpecialVehicles = async () =>{
        const response = await fetch(
            `/api/vehicles/specials?quantity=${Number(vehiclesToShow)}&orderBy=${orderBy}&showBy=${showBy}&customStocks=${customStocks?.toString()}`
        ).then((response) => response.json());
        setData(response.list ?? []);
    };

    const { renderImage } = useImageUtil({
        stockPhoto: "",
        forceImageOverSSL: false,
    });

    useEffect(() => {
        getSpecialVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const defaultImageAsThumbnail = "Image1";

    return (
        <section
            className="feature-car gray-bg page-section-ptb"
            ref={similarVehiclesCarouselRef}
        >
            {similarVehiclesCarouselVisible && <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <HtmlComponent htmlValue={settings.title} />
                    </div>
                </div>
                <div className="row">
                    {data?.map((item: WebsiteVehicleList, index: number) => {
                        const makePar = item.make
                            .replace("&", "AND")
                            .replace(" ", "-");
                        const modelPar = item.model
                            .replace("&", "AND")
                            .replace(" ", "-");
                        const urlComplement = `/carsforsale/${item.year}-${makePar}-${modelPar}-${item.lotName}-${item.lotState}-Stock=${item.stockNumber}`;
                        const altImage = `${companyInfo.companyName} - Used ${makePar} ${modelPar} ${item.year} ${item.lotName} ${item.trim}`;
                        const carDescription = `${item.make} ${item.model} ${item.trim}`;
                        const imageValue = renderImage(item[defaultImageAsThumbnail.toLowerCase()] as string);

                        return (
                            <SpecialCarCard key={item.stockNumber} 
                                car={
                                    {
                                        make: makePar,
                                        model: modelPar,
                                        url: urlComplement,
                                        altImage,
                                        image: imageValue,
                                        description: carDescription,
                                        year: item.year,
                                        transmission: item.transmission,
                                        price: "$" + numberWithCommas(item.internetPrice),
                                        miles: item.miles,
                                        shouldResizeImg: item.hasResizedImages,
                                    }} 
                                columnClass={index===0? 'col-md-6' : 'col-md-3'}
                                height={index===0 ? 412 : 223}
                                width={index===0 ? 750 : 400}
                                isFirst={index===0}
                            />
                        );
                    })}</div>
            </div>}
        </section>
    );
};

export default SpecialVehiclesInCard;
