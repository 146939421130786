const useImageUtil = ({
    stockPhoto,
    forceImageOverSSL,
}: {stockPhoto: string, forceImageOverSSL: boolean}) =>{
    const renderImage = (imageValue: string) => {

        let resultImage = "/static/images/CarInTransit.png";
        if(stockPhoto) {resultImage = stockPhoto;}
        if(imageValue){ resultImage = imageValue;}
    
        resultImage = forceImageOverSSL
            ? resultImage?.replace("http:", "https:")
            : resultImage;

        return resultImage;
    };
 
    
    return { 
        renderImage
    };
};

export default useImageUtil;